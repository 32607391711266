import { createRouter, createWebHistory } from "vue-router";
import store from "../store";
import NProgress from "nprogress";

const routes = [
  {
    name: "GuestLogin",
    path: "/login",
    component: () => import("../components/layouts/Guest.vue"),
    meta: {
      middleware: "guest",
      title: "Login",
    },
    children: [
      {
        name: "login",
        path: "",
        query: {},
        component: () => import("../pages/Auth/Login.vue"),
      },
      {
        name: "register",
        path: "/register",
        component: () => import("../pages/Auth/Register.vue"),
        meta: {
          title: "Register",
        },
      },
      {
        name: "ForgotPassword",
        path: "/forgot-password",
        component: () => import("../pages/Auth/ForgotPassword.vue"),
        meta: {
          title: "Forgot Password",
        },
      },
      {
        name: "ResetPassword",
        path: "/reset-password/:pin_id/:token",
        component: () => import("../pages/Auth/ResetPassword.vue"),
        meta: {
          title: "Reset Password",
        },
      },
      {
        name: "PurchaseForFriendVerification",
        path: "/purchase-for-friend-account-confirmation/:pin_id/:token",
        component: () =>
          import("../pages/PurchaseForFriend/VerificationWithPasswordSet.vue"),
        meta: {
          title: "Verify your account & set password",
        },
      },
      {
        path: "/:catchAll(.*)", // Catch-all route for auth routes
        component: () => import("../pages/NotFound.vue"),
        meta: {
          title: "404 - Page Not Found",
        },
      },
    ],
  },
  {
    name: "auth",
    path: "/",
    component: () => import("../components/layouts/Default.vue"),
    children: [
      {
        path: "",
        name: "dashboard",
        component: () => import("../components/Dashboard"),
        meta: {
          title: `Dashboard`,
        },
      },
      {
        path: "/login-device-histories",
        name: "DeviceHistories",
        component: () => import("../components/DeviceHistories.vue"),
        meta: {
          title: `Device Histories`,
        },
      },
      {
        path: "/latest-purchase",
        name: "LatestPurchase",
        component: () => import("../components/LatestPurchase.vue"),
        meta: {
          title: `Latest Purchase`,
        },
      },
      {
        path: "/payment-histories",
        name: "PaymentHistories",
        component: () => import("../components/PaymentHistories.vue"),
        meta: {
          title: `Payment Histories`,
        },
      },
      {
        path: "/friend-purchase-histories",
        name: "PurchaseForFriendsHistories",
        component: () =>
          import("../components/PurchaseForFriendsHistories.vue"),
        meta: {
          title: `Device Histories`,
        },
      },
      {
        path: "/change-password",
        name: "ChangePassword",
        component: () => import("../pages/Auth/ChangePassword.vue"),
        meta: {
          title: `Home`,
        },
      },
      {
        path: "/change-subscriber-email",
        name: "ChangeSubscriberEmail",
        component: () => import("../pages/Auth/ChangeSubscriberEmail.vue"),
        meta: {
          title: `Home`,
        },
      },
      {
        path: "/:catchAll(.*)", // Catch-all route for auth routes
        component: () => import("../pages/NotFound.vue"),
        meta: {
          title: "404 - Page Not Found",
        },
      },
    ],
  },
  {
    name: "CheckoutPart",
    path: "/checkout/:id",
    component: () => import("../components/layouts/CheckoutDefault.vue"),
    meta: {
      middleware: "checkout",
      title: "Checkout",
    },
    children: [
      {
        name: "Checkout",
        path: "",
        query: {},
        component: () => import("../pages/checkout/Checkout.vue"),
      },
      {
        name: "PurchaseSuccess",
        path: "/purchase/success",
        query: {},
        component: () => import("../pages/checkout/PurchaseSuccess"),
        meta: {
          title: "Purchase Success",
        },
      },
      {
        name: "SocialAuthAttempt",
        path: "/social-auth-attempt",
        query: {},
        component: () => import("../pages/checkout/SocialAuthAttempt"),
        meta: {
          title: "Social Auth Attempt",
        },
      },
      {
        name: "PurchaseSuccessForFriend",
        path: "/purchase/for-friend-success",
        query: {},
        component: () => import("../pages/checkout/PurchaseSuccessForFriend"),
        meta: {
          title: "Purchase Success For Friend",
        },
      },
      {
        name: "PurchaseCancel",
        path: "/purchase/cancel",
        query: {},
        component: () => import("../pages/checkout/PurchaseCancel"),
        meta: {
          title: "Purchase Cancel",
        },
      },
      {
        name: "PurchaseFailed",
        path: "/purchase/failed",
        query: {},
        component: () => import("../pages/checkout/PurchaseFailed"),
        meta: {
          title: "Purchase Failed",
        },
      },
      {
        name: "Pricing",
        path: "/price",
        query: {},
        component: () => import("../pages/Price"),
        meta: {
          title: "Pricing",
        },
      },
      {
        name: "PurchaseForFriends",
        path: "/purchase-for-your-friends",
        query: {},
        component: () => import("../pages/PurchaseForFriend/PurchaseForFriend"),
        meta: {
          title: "Purchase for Your Friend",
        },
      },
      {
        name: "PurchaseForFriendsWeb",
        path: "/purchase-for-your-friends-web",
        query: {},
        component: () =>
          import("../pages/PurchaseForFriend/PurchaseForFriendWeb"),
        meta: {
          title: "Purchase for Your Friend",
        },
      },
      {
        name: "WindowsRedirect",
        path: "/vpn-pay",
        redirect: "/price",
        query: {},
      },
      {
        name: "verifyUserToken",
        path: "/verify-free-sign-up-email/:username/:token/:app_name",
        component: () => import("../pages/Auth/VerifyUserEmail.vue"),
        meta: {
          title: "Verify User Email",
        },
      },
      {
        name: "OtpVerification",
        path: "/otp/verification",
        component: () => import("../pages/Auth/OtpVerification.vue"),
        meta: {
          title: "OTP Verification",
        },
      },
      {
        name: "ResetSubscriberEmail",
        path: "/reset-subscriber-email/:subscriberOndemandRequestId/:verifyToken",
        component: () => import("../pages/Auth/ResetSubscriberEmail.vue"),
        meta: {
          title: "Reset Subscriber Email",
        },
      },
      {
        path: "/:catchAll(.*)", // Catch-all route for auth routes
        component: () => import("../pages/NotFound.vue"),
        meta: {
          title: "404 - Page Not Found",
        },
      },
    ],
  },
];

const router = createRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  let keepSignedIn = store.state.keepSignedIn.keep_signed_in;
  let auth = store.state.auth.status.loggedIn;
  if (to.meta.middleware === "guest") {
    if (auth) {
      next({ name: "dashboard" });
    }
    next();
  } else if (to.meta.middleware === "checkout") {
    next();
  } else {
    if (auth) {
      if (!keepSignedIn) {
        if (!keepSignedIn && auth) {
          store.dispatch("auth/logout");
          next({ name: "login", query: { from: to.path } });
        }
      }
      next();
    } else {
      next({ name: "login", query: { from: to.path } });
    }
  }
});

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start();
  }
  next();
});

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

export default router;

import axiosInstance from "@/plugins/HTTP";
import authHeader from "./auth-header";

class AuthService {
  login(user) {
    //alert("login service");
    return axiosInstance
      .post("login", {
        username: user.username,
        password: user.password,
      })
      .then((response) => {
        if (response.status) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(user) {
    return axiosInstance
      .post("register", {
        verification_type: process.env.VUE_APP_USER_VERIFICAION_TYPE,
        username: user.username,
        email: user.email,
        password: user.password,
        password_confirmation: user.password_confirmation,
        should_receive_promotion: user.should_receive_promotion,
      })
      .then((response) => {
        // if (response.status) {
        //   localStorage.setItem("user", JSON.stringify(response.data));
        // }
        return response.data;
      });
  }

  otpVerification(user) {
    return axiosInstance.post("otp-verification", user).then((response) => {
      return response.data;
    });
  }

  resendVerification(user) {
    return axiosInstance.post("resend-verification", user).then((response) => {
      return response.data;
    });
  }

  changePassword(user) {
    return axiosInstance.post(
      "change-password",
      {
        old_password: user.old_password,
        new_password: user.new_password,
        confirm_password: user.confirm_password,
      },
      {
        headers: authHeader(),
      }
    );
  }

  forgotPassword(user) {
    return axiosInstance.post("forgot-password", {
      username: user.username,
    });
  }

  resetPassword(user, id, token) {
    return axiosInstance.post("reset-password", {
      id: id,
      token: token,
      password: user.password,
      password_confirmation: user.password_confirmation,
    });
  }

  purchaseFriendVerify(user, id, token) {
    return axiosInstance.post("purchase-for-friends-verifications", {
      id: id,
      token: token,
      password: user.password,
      password_confirmation: user.password_confirmation,
    });
  }
}

export default new AuthService();

import axios from "axios";
import NProgress from "nprogress";
import store from "@/store/index.js";
import router from "@/router";
const API_URL = process.env.VUE_APP_API_BASE_POINT;

// create a new axios instance
const axiosInstance = axios.create({
  baseURL: API_URL,
});

// before a request is made start the nprogress
axiosInstance.interceptors.request.use((config) => {
  NProgress.start();
  return config;
});

// before a response is returned stop nprogress
axiosInstance.interceptors.response.use(
  (response) => {
    NProgress.done();
    return response;
  },
  (error) => {
    NProgress.done();
    if (error.response && error.response.status === 401) {
      // Example: redirect to the login page
      store.dispatch("auth/logout");
      router.push("/login");
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;

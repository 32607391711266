import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faArrowRight,
  faShieldAlt,
  faCheck,
  faCircleCheck,
  faMobile,
  faShoppingCart,
  faCreditCard,
  faSearch,
  faEye,
  faEyeSlash,
  faCrown,
  faArrowCircleDown,
  faDownload,
  faServer,
  faUserTag,
  faPercentage,
  faHeadphones,
  faTable,
  faLaptop,
  faHome,
  faBars,
  faUserCircle,
  faUser,
  faCircleUser,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt,
  faToggleOn,
  faToggleOff,
  faPencilSquare,
  faArrowUpRightFromSquare,
  faHistory,
  faTriangleExclamation,
  faCircleInfo,
  faHouse,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";

import {
  faInstagram,
  faYoutube,
  faTwitter,
  faFacebook,
  faAmazonPay,
  faApplePay,
  faGooglePay,
  faCcStripe,
  faApple,
  faWindows,
  faAndroid,
} from "@fortawesome/free-brands-svg-icons";

library.add(
  faPencilSquare,
  faCircleUser,
  faArrowRight,
  faToggleOff,
  faToggleOn,
  faShieldAlt,
  faCheck,
  faCircleCheck,
  faInstagram,
  faYoutube,
  faTwitter,
  faFacebook,
  faMobile,
  faAmazonPay,
  faShoppingCart,
  faApplePay,
  faGooglePay,
  faCreditCard,
  faCcStripe,
  faSearch,
  faAndroid,
  faWindows,
  faApple,
  faEye,
  faEyeSlash,
  faCrown,
  faArrowCircleDown,
  faDownload,
  faServer,
  faUserTag,
  faPercentage,
  faHeadphones,
  faTable,
  faLaptop,
  faHome,
  faBars,
  faUserCircle,
  faUser,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt,
  faArrowUpRightFromSquare,
  faHistory,
  faTriangleExclamation,
  faCircleInfo,
  faHouse,
  faCopy
);

export { FontAwesomeIcon };
